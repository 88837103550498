import React, { useEffect } from 'react';
import "./Lending.css"
import { handleAuthButtonClick, oauth } from '../../api/login';
import Footer from '../../components/Footer/Footer';
import { accessToken } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';

function Lending() {
  const navigate = useNavigate();


  const faqItems = [
    {
      answer: `Заполните название, логин и пароль от локальной сети Wi-Fi. После нажатия на кнопку начнется процесс компиляции. По окончанию компиляции подключите устройство по USB и нажмите на кнопку "Установить прошивку".`,
      title: "как добавить новое устройство?",
    },
    {
      answer: `Чтобы обновить прошивку, мы можете модифицировать базовую прошивку устройства для ваших целей. Если устройство подключено к платформе через базовую прошивку, откройте окно устройства и выберите файл .ino`,
      title: "как обновить прошивку устройства?",
    },
  ];

  useEffect(() => {
    oauth();
    if (accessToken) {
      navigate('/control')
    }
  }, [])

  return (
    <div className="lending">
      <div className="flex flex-col items-center justify-center min-h-[25dvh] min-h-vh text-center lg:pt-[15px]" id="logo-container">
        <div id="logo-landing" className="rounded">
          <div>
            <svg viewBox="0 0 210 50" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="0" width="10" height="10" fill="#000000"></rect>
              <rect x="0" y="20" width="10" height="20" fill="#000000"></rect>
              
              <rect x="20" y="10" width="10" height="30" fill="#000000"></rect>
              <rect x="20" y="10" width="30" height="10" fill="#000000"></rect>
              <rect x="40" y="10" width="10" height="30" fill="#000000"></rect>
              <rect x="20" y="30" width="30" height="10" fill="#000000"></rect>
              
              <rect x="60" y="10" width="10" height="50" fill="#000000"></rect>
              <rect x="60" y="10" width="30" height="10" fill="#000000"></rect>
              <rect x="60" y="30" width="30" height="10" fill="#000000"></rect>
              <rect x="80" y="10" width="10" height="30" fill="#000000"></rect>
              
              <rect x="100" y="10" width="10" height="50" fill="#000000"></rect>
              <rect x="100" y="10" width="30" height="10" fill="#000000"></rect>
              <rect x="100" y="30" width="30" height="10" fill="#000000"></rect>
              <rect x="120" y="10" width="10" height="30" fill="#000000"></rect>
              
              <rect x="140" y="30" width="10" height="10" fill="#00FE3D"></rect>
              
              <rect x="160" y="0" width="10" height="10" fill="#000000"></rect>
              <rect x="160" y="20" width="10" height="20" fill="#000000"></rect>
              
              <rect x="180" y="10" width="10" height="30" fill="#000000"></rect>
              <rect x="180" y="10" width="30" height="10" fill="#000000"></rect>
              <rect x="200" y="10" width="10" height="30" fill="#000000"></rect>
              <rect x="180" y="30" width="30" height="10" fill="#000000"></rect>
            </svg>
            <div className="links-container text-[24px] m-5">
              <a href="#about">about</a>
              <span className="separator">|</span> 
              <a href="#footer">docs</a>
              <span className="separator">|</span>
              <a href="#faq">FAQ</a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center lg:pb-9 pb-5">
        <button 
          onClick={() => handleAuthButtonClick()} 
          id="login-button" 
          className="login-button text-[2em] w-[200px] lg:w-[500px] hover:text-green-500 border bg-white border-black"
        >
          log in
        </button>
      </div>

      <div className="pb-5 lg:p-10">
        <h2 id="about">iopp.io</h2>
          <p>
            универсальный облачный сервис, который позволяет легко подключать, управлять и мониторить устройства на базе ESP (ESP8266, ESP32) в рамках ваших DIY проектов. Сервис предлагает широкий спектр функций, делая управление умным домом
            или другими проектами интуитивно понятным и удобным.
          </p>

      <div className="features lowercase">

        <div className="feature">
          <h3 className="pb-3">простое подключение</h3>
            <ul>
              <li>поддержка популярных платформ ESP (ESP8266, ESP32).</li>
              <li>интуитивный интерфейс для настройки подключения устройств к облаку.</li>
              <li>прошивка прямо через браузер или OTA</li>
            </ul>
        </div>

        <div className="feature">
          <h3 className="pb-3">управление и мониторинг</h3>
          <ul>
            <li>удаленное управление устройствами через веб-интерфейс или мобильное приложение.</li>
            <li>визуализация данных с датчиков в реальном времени.</li>
            <li>создание сценариев для автоматизации управления устройствами.</li>
            </ul>
        </div>

        <div className="feature">
          <h3 className="pb-3">аналитика и статистика</h3>
          <ul>
            <li>сбор и хранение данных с устройств в облаке.</li>
            <li>генерация отчетов и графиков для анализа.</li>
          </ul>
        </div>

        <div className="feature">
          <h3 className="pb-3">интеграции</h3>
          <ul>
            <li>поддержка <span className="yandex-gradient text-[1.1rem]">Яндекс Алисы</span> для управления устройствами голосом.</li>
            <li>возможность создания собственных команд для голосового управления.</li>
            <li>подключение к мобильному приложению Умный дом</li>
          </ul>
        </div>

        <div className="feature">
          <h3 className="pb-3">триггеры и оповещения</h3>
          <ul>
            <li><span>настройка триггеров</span> на основе данных с датчиков или других событий.</li>
            <li>отправка оповещений на электронную почту, Telegram.</li>
          </ul>
        </div>

        <div className="feature">
          <h3 className="pb-3">Среда разработки</h3>
          <ul>
            <li>IDE на платформе с возможностью скомпилировать и загрузить новую прошивку на устройство</li>
          </ul>
        </div>

      </div>
      <hr />

      <p className="advantages border">
        iopp.io - это идеальное решение для тех, кто хочет создать умный дом или другой проект на базе ESP устройств, не тратя время на сложную настройку и программирование. Сервис предлагает все необходимые инструменты для управления,
        мониторинга и автоматизации ваших устройств в облаке.
      </p>
      <hr />

      <h2 id="faq">FAQ</h2>
      {faqItems.map((faq) => (
      <div>
        <div className="pb-5 mt-5">{faq.title}</div>
          <div className="bg-slate-100 border p-2 lowercase">{faq.answer}</div>
        </div>
      ))}
    </div>
    <Footer id='footer' />
  </div>
  );
}

export default Lending;
