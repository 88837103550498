
import React from 'react';
import './Footer.css';

function Footer() {
    const legalNodes = [
        {
          href: "docs/privacy.pdf",
          text: "Обработка персональных данных",
        },
        {
          href: "docs/offer.pdf",
          text: "Договор оферты",
        },
        {
          href: "docs/payments.pdf",
          text: "Политика безопасности платежей",
        },
        {
          href: "docs/payment_and_refund.pdf",
          text: "Платежи и тарифы",
        },
        {
          href: "docs/agreement.pdf",
          text: "Пользовательское соглашение",
        },
        {
          href: "docs/contacts.pdf",
          text: "Реквизиты и контакты",
        },
      ];

      
    return (
        <div className="flex flex-col w-full bg-[#fffef2]" id="footer">
            <div className="px-5 py-7 lg:py-10 lg:px-[100px] flex flex-col justify-between lg:flex-row gap-4 lg:gap-[10px]">
                <div className="flex flex-col gap-3">
                    <div className="text-h5 leading-paragraph font-semibold">
                        Документация
                    </div>
                    <div className="flex flex-col gap-1">
                        {legalNodes.map((node) => (
                        <a 
                            href={node.href} 
                            target="_blank" 
                            className="underline text-paragraph-sm leading-paragraph hover:text-primary-hover transition-all"
                        >
                            {node.text}
                        </a>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-3 lg:max-w-[40%]">
                    <div className="text-h5 leading-paragraph font-semibold">
                        Контакты
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="text-paragraph-sm leading-paragraph flex gap-2">
                            Юридический адрес: 445044, Россия, Самарская область, г. Тольятти, ул. Автостроителей, д. 36, кв. 107
                        </div>
                        <div className="text-paragraph-sm leading-paragraph flex">
                            <a className="text-inherit" href="mailto:iopp.io@yandex.ru">
                                iopp.io@yandex.ru
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shrink-0 flex flex-col lg:flex-row gap-2 lg:gap-5 items-center lg:h-[30px] w-full bg-gray-100 p-5 lg:px-[100px]">
                <div className="text-paragraphleading-paragraph">© 2024 <span className="font-bold">iopp</span></div>
                <div className="text-paragraph-sm text-typo-muted">
                    ООО {'"Альфа Лайн"'}, ИНН 6321409124, КПП 632101001
                </div>
            </div>
        </div>
    );
}

export default Footer;