import React, { useEffect, useState } from 'react';
import './App.css';
import Lending from './pages/Lending/Lending';
import {
  Routes,
  Route,
} from 'react-router-dom';
import ControlPage from './pages/ControlPage/ControlPage';
import { accessToken } from './constants/constants';
import { useDispatch } from 'react-redux';
import { setFirmware } from './redux/firmware';
import { WS_URL } from './constants/constants';

function App() {
  const websocket_channel = localStorage.getItem('websocket_channel');
  const dispatch = useDispatch();

  async function connectWebSocket(channel) {
    
    let socket = new WebSocket(WS_URL);
    let retryCount = 0;
    const maxRetries = 5;

    socket.onopen = () => {
      console.log('WebSocket connection opened.');
      retryCount = 0;
      socket.send(JSON.stringify({ command: "join", args: { channel: channel } }));
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'firmware') {
        dispatch(setFirmware(data));
      } else if (data.type === 'message') {
          console.log(data)
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed.');
      if (retryCount < maxRetries) {
        const retryDelay = Math.min(100 * Math.pow(2, retryCount), 30000);
        retryCount++;
        setTimeout(() => connectWebSocket(channel), retryDelay);
      } else {
        console.loig("Error reconnecting to WebSocket. Please contact support or try again later.");
      }
    };
  }

  useEffect(() => {
    connectWebSocket(websocket_channel)
  }, [websocket_channel])
  
  return (
    <Routes>
      <Route path="/" element={<Lending /> } />
      {accessToken &&
        <>
          <Route path="/control" element={<ControlPage/> } />
        </>
      }
    </Routes>
  );
}

export default App;
