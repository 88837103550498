import React, { useState, useEffect } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { material } from '@uiw/codemirror-theme-material';
import { useSelector } from 'react-redux';
import { uploadFile } from '../../api/devices';

function Ide({ handleFileChange, file, deviceId }) {
  const { firmware } = useSelector((state) => state.firmware);

  const [value, setValue] = useState();
  const [installVisiable, setInstallVisiable] = useState();

  const fetchFileContent = async (file) => {
    try {
      const response = await fetch('https://iopp.io/media/build_files/base/base.ino?1'); //поменять ссылку на file из useState
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const text = await response.text();
      setValue(text);
    } catch (error) {
      console.log(error)
    }
  };

  console.log(deviceId)

  useEffect(() => {
    fetchFileContent(file);
  }, [file])

  const onChange = React.useCallback((val) => {
    setValue(val);
  }, []);
  
  return (
    <>
      <div className="mt-4 mb-4 pl-6">
        <label for="firmwareFile" className="block text-sm font-medium text-gray-700">Загрузить новую прошивку:</label>
        <input 
          type="file" 
          id="firmwareFile" 
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
          onChange={(event) => handleFileChange(event)}
        />
      </div>
      <CodeMirror theme={material} value={value} height="70vh" width='70vw' extensions={[javascript({ jsx: true })]} onChange={onChange} />
      <div id="result" class="mt-2 text-left pl-6 pr-6">
        <button 
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-4 mb-4" 
          onClick={() => uploadFile(value, deviceId, setInstallVisiable)}
        >
          Загрузить
        </button>
        {(installVisiable === true && !firmware?.payload?.status) &&
          <div id="loader" className="flex items-center justify-center mt-2 text-gray-600">
            ожидание компиляции прошивки...
            <div className="ml-2 animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-yellow-600"></div>
          </div>
        }
        {(installVisiable === true && firmware?.payload?.status && firmware?.payload?.status === 'success') &&
          <esp-web-install-button manifest="${data.payload.firmware_manifest}" class="mt-4">
            <button 
              class="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mb-4" 
              slot="activate"
            >
              Установить прошивку
            </button>
          </esp-web-install-button>
        }
        {(firmware?.payload?.status && firmware.payload.status !== 'success') &&
          <h2 class="text-lg font-medium text-red-600">Firmware compilation error. Please contact support or try again later.</h2>
        }
      </div>
    </>
  );
}
export default Ide;