import React, { useState } from 'react';

function Qestions({ 
    buttonClicked, 
    setButtonClicked, 
    item 
}) {
    const [elementOpen, setIsElementOpen] = useState(false)

    const buttonClickHandle = (item) => {
        if (buttonClicked?.includes(item) === true)  {
            var index = buttonClicked.indexOf(item);
            if (index !== -1) {
                buttonClicked.splice(index, 1);
            }
            setIsElementOpen(false);
            console.log(buttonClicked)
        } else {
            setButtonClicked([...buttonClicked, item])
            setIsElementOpen(true);
            console.log(buttonClicked)
        }
    }

    return (
        <div className="faq-item w-full pr-8">
            <button 
                className="text-lg font-semibold mb-2 w-full text-left bg-gray-200 p-2 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
                onClick={() => buttonClickHandle('1')}
            >
                {item?.buttonText}
            </button>
            <div className={`faq-answer p-1 ${elementOpen === false && 'hidden'}`}>
                {item?.text1 &&
                    <p>
                        {item?.text1}
                    </p>
                }
                <hr/>
                {item?.text2 && 
                    <p>
                        {item?.text2}
                    </p>
                }
            </div>
        </div>
    );
}

export default Qestions;
