import { createSlice } from '@reduxjs/toolkit'

const firmware = createSlice({
	name: 'firmware',
	initialState: {
		firmware: '',
	},
	reducers: {
		setFirmware(state, action) {
			state.firmware = action.payload
		}
	}
})

export default firmware.reducer

export const { 
	setFirmware,
} = firmware.actions