import { combineReducers, configureStore } from '@reduxjs/toolkit';
import firmware from '../firmware';

const rootReducer = combineReducers({
	firmware,
})

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	})
})