import React, { useState, useEffect } from 'react';
import './ControlPage.css';
import Devices from '../../components/Devices/Devices';
import Qestions from '../../components/Qestions/Qestions';
import { 
    postDevices, 
    fetchDevices, 
    getTarifs, 
    deleteDevice, 
    getDeviceInfo, 
    getFirmware,
    handleWifi,
} from '../../api/devices';
import DeviceModal from '../../components/DeviceModal/DeviceModal';
import FirmwareInfoModal from '../../components/FirmwareInfoModal/FirmwareInfoModal';
import { useDispatch, useSelector } from 'react-redux';
import { setFirmware } from '../../redux/firmware';

const faqElements = [
    {
        buttonText: 'Как добавить новое устройство?',
        text1: 'Заполните название, логин и пароль от локальной сети Wi-Fi. После нажатия на кнопку начнется процесс компиляции. По окончанию компиляции подключите устройство по USB и нажмите на кнопку "Установить прошивку".',
        text2: 'Также вы можете самостоятельно прошить устройство, для этого вам нужно создать устройство и скопировать UUID и пароль в окне устройства. Эти данные нужно подставить в базовую прошивку.',
    },
    {
        buttonText: 'Как обновить прошивку устройства?',
        text1: 'Чтобы обновить прошивку, мы можете модифицировать базовую прошивку устройства для ваших целей. Если устройство подключено к платформе через базовую прошивку, откройте окно устройства и выберите файл .ino'
    }
]

function ControlPage() {
    const dispatch = useDispatch();
    const { firmware } = useSelector((state) => state.firmware);

    const [buttonClicked, setButtonClicked] = useState([]);
    const [devices, setDevices] = useState([]);
    const [tarifs, setTarifs] = useState([]);
    const [price, setPrice] = useState(1);
    const [name, setName] = useState();
    const [deviceModal, setDeviceModal] = useState(false);
    const [firmwareInfoModal, setFirmwareInfoModal] = useState(false);
    const [deviceId, setDeviceId] = useState();
    const [file, setFile] = useState(null);
    const [deviceData, setDeviceData] = useState();
    const [id, setId] = useState(null);
    const [wifiPassword, setWifiPassword] = useState();
    const [wifi, setWifi] = useState();
    const [installVisiable, setInstallVisiable] = useState();
    const [device, setDevice] = useState();
    const [firmwareInfo, setFirmwareInfo] = useState();

    useEffect(() => {
        fetchDevices(setDevices);
        getTarifs(setTarifs);
    }, [])

    const installFirmware = () => {
        handleWifi(id, wifiPassword, wifi);

        // let name = document.getElementById('name');
        // let wifiSSID = document.getElementById('wifiSSID');
        // let wifiPass = document.getElementById('wifiPassword');

        // name.value = '';
        // wifiSSID.value = '';
        // wifiPass.value = '';
        // setInstallVisiable(false);
    }

    useEffect(() => {
        if (id !== null) {
            installFirmware()
        }
    }, [id])

    const addNewDevice = async () => {
        await postDevices(name, price, setId);
        await fetchDevices(setDevices);
        setInstallVisiable(true);
    }

    const openDeviceModal = (device_id, device) => {
        getDeviceInfo(device_id, setDeviceData);
        setDeviceId(device_id);
        setDevice(device);
        setDeviceModal(true);
        getFirmware(device_id, setFirmwareInfo);
    }

    const openFirmwareInfoModal = (event, device_id) => {
        event.stopPropagation();
        setFirmwareInfoModal(true);
        getFirmware(device_id, setFirmwareInfo);
    }

    const closeDeviceModal = () => {
        setDeviceModal(false);
        dispatch(setFirmware(null))
    }

    const closeFirmwareInfoModal = () => {
        setFirmwareInfoModal(false);
    }

    const handleFileChange = (event) => {
        if (event.target.files) {
          setFile(event.target.files[0]);
        }
    };

    const removeDevice = async (device_id) => {
        await deleteDevice(device_id);
        closeDeviceModal();
        fetchDevices(setDevices);
    }

    return (
        <>
            <div id="mainContainer" className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl flex flex-col">
                <div className="w-full pr-8">
                    <div id="mainSection" className="">
                        <h1 className="text-4xl font-bold text-blue-600 mb-4 text-center">
                            iopp.io - IoT платформа
                        </h1>
                        <div id="wifiForm" className="space-y-4">
                            <div>
                                <label for="name" className="block text-sm font-medium text-gray-700">Название устройства:</label>
                                <input 
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    required="" 
                                    placeholder="My ESP8266" 
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div>
                                <label for="price" className="block text-sm font-medium text-gray-700">Тариф устройства:</label>
                                <select 
                                    type="text" 
                                    id="price" 
                                    name="price" 
                                    required=""
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(e) => {setPrice(e.target.value); console.log(price)}}
                                >
                                    <option defaultValue>Выберит Тариф</option>
                                    {tarifs.length > 1 && <option value="0" selected>Выберите тариф</option>}
                                    {tarifs.map((item, index) => {
                                        return (
                                            <option 
                                                key={index} 
                                                value={item.id} 
                                            >
                                                {item.name} Price: {item?.price}$
                                            </option>
                                        )}
                                    )} 
                                </select>
                            </div>
                            <div> 
                                <label for="wifiSSID" className="block text-sm font-medium text-gray-700">Wi-Fi SSID:</label>
                                <input 
                                    type="text" 
                                    id="wifiSSID" 
                                    name="wifiSSID" 
                                    placeholder="Название локальной Wi-Fi сети" 
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(e) => setWifi(e.target.value)}
                                />
                            </div>
                            <div>
                                <label for="wifiPassword" className="block text-sm font-medium text-gray-700">Wi-Fi Пароль:</label>
                                <input 
                                    type="password" 
                                    id="wifiPassword" 
                                    name="wifiPassword" 
                                    placeholder="Пароль Wi-Fi" 
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(e) => setWifiPassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <button 
                                    type="button" 
                                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mb-4"
                                    onClick={() => addNewDevice()}
                                >
                                    Добавить устройство
                                </button>

                                {(installVisiable === true && !firmware?.payload?.status) &&
                                    <div id="loader" className="flex items-center justify-center mt-2 text-gray-600 mb-4">
                                        ожидание компиляции прошивки...
                                        <div className="ml-2 animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-yellow-600"></div>
                                    </div>
                                }

                                
                                <div id="result" class="mt-2 text-left">
                                    { (installVisiable === true && firmware?.payload?.status === 'success') &&
                                        <esp-web-install-button manifest="${data.payload.firmware_manifest}" class="mt-4">
                                            <button 
                                                class="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" 
                                                slot="activate"
                                            >
                                                Установить прошивку
                                            </button>
                                        </esp-web-install-button>
                                    }
                                    {(firmware?.payload?.status && firmware.payload.status !== 'success') &&
                                        <h2 class="text-lg font-medium text-red-600">Firmware compilation error. Please contact support or try again later.</h2>
                                    }
                                </div>
                                
                            </div>
                        </div>

                        

                        <div id="result" className="mt-2 text-left">
                        </div>
                        <a id="fbase" href="" download="" className="text-blue-600 py-2">
                            Скачать базовую прошивку
                        </a>
                    </div>
                </div>

                <div id="faq-section" className="bg-gray-50 shadow-lg rounded-lg p-6">
                    <h2 className="text-2xl font-semibold mb-4">Часто задаваемые вопросы (FAQ)</h2>
                    {faqElements.map((item, index) => {
                        return (
                            <Qestions 
                                key={index}
                                buttonClicked={buttonClicked} 
                                setButtonClicked={setButtonClicked} 
                                item={item}
                            />
                        );
                    })}
                </div>

            </div>
            <Devices 
                devices={devices}
                openDeviceModal={openDeviceModal}
                openFirmwareInfoModal={openFirmwareInfoModal}
            />

            {deviceModal === true &&
                <DeviceModal 
                    closeDeviceModal={closeDeviceModal}
                    deviceData={deviceData}
                    deviceId={deviceId}
                    handleFileChange={handleFileChange}
                    file={file}
                    device={device}
                    removeDevice={removeDevice}
                    firmwareInfo={firmwareInfo}
                />
            }

            {firmwareInfoModal === true &&
                <FirmwareInfoModal
                    firmwareInfo={firmwareInfo}
                    closeFirmwareInfoModal={closeFirmwareInfoModal}
                />
            }
        </>
    );
}

export default ControlPage;
