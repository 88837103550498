import React from 'react';

function FirmwareInfoModal({ firmwareInfo, closeFirmwareInfoModal }) {
     
    return (
        <div className="PopupUI Popup">
            <div className="PopupUI-Block">
                <button className="PopupUI-Close" onClick={() => closeFirmwareInfoModal()}>
                    <svg viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" className="PopupUI-CloseIcon Icon">
                        <path xmlns="http://www.w3.org/2000/svg" d="M7.38821 1.49565C7.63229 1.25157 7.63229 0.855847 7.38821 0.611769C7.14413 0.367692 6.7484 0.367692 6.50433 0.611769L3.99999 3.1161L1.49566 0.61177C1.25158 0.367692 0.855855 0.367692 0.611777 0.611769C0.367699 0.855847 0.367699 1.25158 0.611777 1.49565L3.11611 3.99999L0.611769 6.50433C0.367692 6.7484 0.367691 7.14413 0.611769 7.38821C0.855847 7.63229 1.25158 7.63229 1.49565 7.38821L3.99999 4.88387L6.50433 7.38821C6.74841 7.63229 7.14414 7.63229 7.38822 7.38821C7.63229 7.14413 7.63229 6.7484 7.38822 6.50433L4.88388 3.99999L7.38821 1.49565Z"></path>
                        <title xmlns="http://www.w3.org/2000/svg">Закрыть окно</title>
                    </svg>
                </button>
                <div className="PopupUI-Main">
                    <h2 className="Popup-Title">Данные прошивки</h2>
                    <div className="Popup-Form">
                        {firmwareInfo?.map((item, index) => {
                            return (
                                <div className='outline outline-2 outline-offset-2 mt-4 p-5' key={index}>
                                    <p>Дата прошивки: {item.builds[0].created_at}</p>
                                    <p>Статус прошивки: {item.builds[0].status}</p>
                                    <p>ID задачи: {item.builds[0].task_id}</p>
                                    { item.builds[0].manifest !== null && 
                                        <esp-web-install-button manifest={item.builds[0].manifest} id="manifest" className="mt-4 hidden">
                                            <button 
                                                className="w-full text-black bg-emerald-200 py-2 px-4 rounded-md hover:bg-emerald-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400 mt-2" 
                                                slot="activate"
                                            >
                                                Переустановить базовую прошивку через USB
                                            </button>
                                        </esp-web-install-button>
                                    }
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FirmwareInfoModal;