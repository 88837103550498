import merge from 'lodash/merge'
import { configureRefreshFetch, fetchJSON } from 'refresh-fetch'

const saveToken = access_token => {localStorage.setItem('access_token', access_token)}
const clearToken = () => {localStorage.clear();}

const fetchJSONWithToken = (url, options = {}) => {
  const token = localStorage.getItem('access_token');
  
  let optionsWithToken = options
  if (token != null || undefined) {
    optionsWithToken = merge({}, options, {
      headers: {
        Authorization: localStorage.getItem('access_token')
      }
    })
  }

  return fetchJSON(url, optionsWithToken)
}

const shouldRefreshToken = error =>
  error.response.status === 401

const refreshToken = () => {
  return fetchJSONWithToken('/api/refresh-token', {
    method: 'POST'
  })
    .then(response => {
      saveToken(response.body.token)
      return response
    })
    .catch(error => {
      clearToken()
      throw error
    })
}

export const fetch = configureRefreshFetch({
  shouldRefreshToken,
  refreshToken,
  fetch: fetchJSONWithToken,
})
