
import React, { useEffect, useState } from 'react';
import './DeviceModal.css';
import { uploadFirmware } from '../../api/uploadFirmware';
import { getDeviceHistory, updateDeviceState } from '../../api/devices';
import Ide from '../Ide/Ide';

function DeviceModal({ 
    closeDeviceModal,
    deviceData, 
    deviceId,
    handleFileChange, 
    file,
    device,
    removeDevice,
    firmwareInfo,
}) {

    const [deviceHistory, setDeviceHistory] = useState(null);
    const [newState, setNewState] = useState();

    const showDeviceHistory = () => {
        getDeviceHistory(deviceId, setDeviceHistory);
    }

    const sendState = () => {
        updateDeviceState(deviceId, newState);
    }
    
    useEffect(() => {
        if(file !== null && deviceId !== undefined) {
            uploadFirmware(deviceId, file)
        }
    }, [file])
 
    return (
        <div className="PopupUI Popup">
            <div className="PopupUI-Block">
                <div className="bg-white p-6 rounded-lg border-2 border-gray-300">
                    <h3 id="deviceModalTitle" className="text-xl mb-4"><strong>Информация об устройстве:</strong></h3>
                    
                    <div id="deviceModalContent" className="text-gray-700">
                        <div id="lastOnline">Последний раз в сети: { deviceData?.last_online }</div>
                        <div id="status">Статус: { deviceData?.status }</div>
                        {/* <button id="deviceFirmware" className="text-blue-600" onClick={ () => openIde() }>Текущий файл прошивки</button> */}
                        <p><strong>MQTT_ID:</strong> <span id="deviceId"> { device?.id } </span></p>
                        <p><strong>MQTT_DEVICE:</strong> <span id="deviceUUID"> { device?.user_id } </span></p>
                        <p><strong>MQTT_PASSWORD:</strong> <span id="devicePassword"> {device?.password} </span></p>
                        <br/>
                    </div>
                    
                    <div className="mt-4">
                        <label for="currentStatus" className="block text-sm font-medium text-gray-700">Текущее состояние устройства:</label>
                        <textarea id="currentStatus" rows="3" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" value={ deviceData?.state } readOnly></textarea>
                    </div>
                    
                    <div className="mt-4">
                        <label for="newState" className="block text-sm font-medium text-gray-700">Отправить новое состояние:</label>
                        <textarea 
                            id="newState" 
                            rows="3" 
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                            onChange={(event) => setNewState(event.target.value)}
                        ></textarea>
                        <button 
                            id="sendStateBtn" 
                            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mt-2"
                            onClick={() => sendState()}
                        >
                            Отправить состояние
                        </button>
                    </div>
    
                    <button 
                        id="getHistoryBtn" 
                        className="w-full bg-gray-300 text-black py-2 px-4 rounded-md hover:bg-gray-400 mt-4"
                        onClick={() => showDeviceHistory()}
                    >
                        Посмотреть историю
                    </button>
                    
                    <div className="mt-4 flex justify-end space-x-4">
                        <button 
                            id="deleteDeviceBtn" 
                            className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
                            onClick={(event) => removeDevice(deviceId)}
                        >
                            Удалить
                        </button>
                        <button 
                            id="closeModalButton" 
                            className="bg-gray-300 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-400" 
                            onClick={() => closeDeviceModal()}
                        >
                            Закрыть
                        </button>
                    </div>

                    <div id="historyResult" className="mt-4 text-gray-700">
                        {(deviceHistory !== null && deviceHistory?.data?.length < 1) &&   
                            <div class="bg-white p-4 rounded-md shadow mb-4">
                                <p><strong>Нет истории</strong></p>
                             </div>
                        }
                        {deviceHistory?.data?.map((item, index) => {
                            return (
                                <div class="bg-white p-4 rounded-md shadow mb-4" key={index}>
                                    <p><strong>Время:</strong> ${item.time}</p>
                                    <p><strong>Тема:</strong> ${item.topic}</p>
                                    <p><strong>Сообщение:</strong> ${item.msg}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div id="deviceModalCode" className="text-gray-700">
                    <div className="bg-white pb-6 pt-6 rounded-lg">
                        <Ide 
                            handleFileChange={handleFileChange}
                            file={firmwareInfo?.[0]?.file}
                            deviceId={deviceId}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeviceModal;