import { API_URL } from "../constants/constants";
import { fetch } from './refreshToken.js'

export async function fetchDevices(setDevices) {
     try {
        const response = await fetch(`${API_URL}/device/devices`, {
            method: 'GET',
    });
        const data = await response;
        setDevices(data.body.devices);
    } catch (error) {
        console.error('Error fetching devices:', error);
    }
}

export async function getTarifs(setTarifs) {
    try {
       const response = await fetch(`${API_URL}/tariffs`, {
           method: 'GET',
   });
       const data = await response;
       setTarifs(data.body);
   } catch (error) {
       console.error('Error fetching devices:', error);
   }
}

export async function postDevices(name, price, setId) {
    let device = {
        name: name,
        tariff_id: price
    };
    try {
       const response = await fetch(`${API_URL}/device/devices`, {
           method: 'POST',
           headers: {
            'Content-Type': 'application/json;'
           },
           body: JSON.stringify(device)
   });

    setId(response.body.device_id)
   } catch (error) {
       console.error('Error fetching devices:', error);
   }
}

export async function deleteDevice(device_id) {
    try {
       const response = await fetch(`${API_URL}/device/devices/${device_id}`, {
           method: 'DELETE',
        });
    } catch (error) {
       console.error('Error fetching devices:', error);
    }
}

export async function getDeviceInfo(device_id, setDeviceData) {
    try {
       const response = await fetch(`${API_URL}/device/devices/${device_id}`, {
           method: 'GET',
        });

        setDeviceData(response.body)
    } catch (error) {
       console.error('Error fetching devices:', error);
    }
}

export const getFirmware = async (device_id, setFirmwareInfo) => {
    try {
        const response = await fetch(`${API_URL}/device/firmware/${device_id}`, {
            method: 'GET',
        });

        setFirmwareInfo(response.body.firmwares);   
    } catch (error) {
        console.error(error);
    }
};

export const getDeviceHistory = async (device_id, setDeviceHistory) => {
    try {
        const response = await fetch(`${API_URL}/device/devices/${device_id}/history?from_ts=1&to_ts=1&limit=10&offset=0`, {
            method: 'GET',
        });

        setDeviceHistory(response.body);   
    } catch (error) {
        console.error(error);
    }
};

export const uploadFile = async (value, device_id, setInstallVisiable) => {
    const blob = new Blob([value]);
    const formData = new FormData();
    formData.append('firmware', blob, 'code.ino');
    setInstallVisiable(true);
    try {
        const response = await fetch(`${API_URL}/device/firmware/upload/${device_id}`, {
            method: 'POST',
            headers: new Headers({Authorization: localStorage.getItem('access_token')}),
            body: formData,
        });

        console.log('File uploaded successfully');
    } catch (error) {
        console.error('Error uploading file:', error);
        setInstallVisiable(false);
    }
};


export const updateDeviceState = async (device_id, deviceState) => {
    try {
        const response = await fetch(`${API_URL}/device/devices/${device_id}/update_state`, {
            method: 'POST',
            body: JSON.stringify({state: deviceState})
        });

        console.log(response)
    } catch (error) {
        console.error(error);
    }
};

export const handleWifi = async (device_id, wifi_ssid, wifi_password) => {
    try {
        const firmwareResponse = await fetch(`${API_URL}/firmware-builder/generate-firmware`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ device_id, wifi_ssid, wifi_password })
        });
        console.log(firmwareResponse)
    } catch (error) {
        console.error('Error submitting form:', error);
    }
}
