import React from 'react';
import './Devices.css';

function Devices({ 
    devices, 
    openDeviceModal,
    openFirmwareInfoModal
}) {

    return (
        <div id="deviceListSection" className="w-full bg-gray-50 p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">Мои устройства</h2>
            <hr/>
            <ul id="deviceList" className="space-y-4">  
                {devices.map((item, index) => {
                    return (
                        <li 
                            key={index} 
                            className="cursor-pointer p-4 bg-white rounded-md shadow hover:bg-gray-100"
                            onClick={() => openDeviceModal(item.id, item)}
                        >
                            <strong>Name: </strong>{ item.name }
                            <br/>
                            <strong>Version</strong>: 0.1
                            <br/>
                            <button className='Button Button_frimeware' onClick={(event) => openFirmwareInfoModal(event, item.id)}>
                                Данные прошивки
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default Devices;